import { graphql, useStaticQuery } from "gatsby";

export interface SiteMetadataProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      siteUrl: string;
    };
  };
}

export const useSiteMetadata = () => {
  const data = useStaticQuery<SiteMetadataProps>(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  return data.site.siteMetadata;
};
