import React, { PropsWithChildren } from "react";
import { NavBar } from "./navbar";

export const MainLayout = ({ children }: PropsWithChildren) => {
  return (
    <>
      <NavBar />
      {children}
    </>
  );
};
