import React from "react";
import "@fontsource/outfit";

import "./src/themes/global.css";
import { MainLayout } from "./src/components/main-layout";

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <MainLayout {...props}>{element}</MainLayout>;
};
