import React from "react";
import styled from "@emotion/styled";

import { useSiteMetadata } from "../hooks/use-site-metadata";
import { Typography } from "./typography";
import { HREF_NAVBAR } from "../constants/constants";

const StyledNavBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 31px;

  height: 62px;

  background: #000000;
`;

export const NavBar = () => {
  const { title } = useSiteMetadata();
  return (
    <div id={HREF_NAVBAR}>
      <StyledNavBar>
        <Typography variant="title">{title}</Typography>
      </StyledNavBar>
    </div>
  );
};
