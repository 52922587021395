import React from "react";
import styled from "@emotion/styled";

import { Colors } from "../themes/colors";

const StyledTitle = styled.h1`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;

  color: ${Colors.white};

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

const StyledSubtitle = styled.h2`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  color: ${Colors.white};
`;

const StyledLabel = styled.label`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: ${Colors.white};
`;

const StyledText = styled.p`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: ${Colors.white};
`;

type VariantFormat = "title" | "label" | "text" | "subtitle";

interface TypographyProps {
  variant: VariantFormat;
  children: any;
  color?: string;
  size?: string;
  style?: React.CSSProperties;
}
export const Typography = (props: TypographyProps) => {
  let style = props.style || {};
  if (props.color) {
    style.color = props.color;
  }
  if (props.size) {
    style.fontSize = props.size;
  }
  switch (props.variant) {
    case "title":
      return <StyledTitle style={style}>{props.children}</StyledTitle>;
    case "subtitle":
      return <StyledSubtitle style={style}>{props.children}</StyledSubtitle>;
    case "label":
      return <StyledLabel style={style}>{props.children}</StyledLabel>;
    case "text":
      return <StyledText style={style}>{props.children}</StyledText>;
    default:
      return <></>;
  }
};
